import React from "react";
import Game from "./components/Game";
import Footer from "./Footer";
import "./index.css";

function App() {
  return (
    <div >
        
      <Game />
      <Footer></Footer>
    </div>
  );
}

export default App;
